import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useNousPage } from "../services/firestore";
import { Highlight } from '../components/Highlight'

const NousPageCell = ({ title, children }) => {
  return (
    <dl>
      <dt className="text-2xl font-semibold mb-4">{title}</dt>
      <dd className="text-base leading-relaxed">{children}</dd>
    </dl>
  )
}

const AboutUsInfo = (aboutUsText) => {
  return (
    <div class="mb-8 lg:mb-0">
      <section>
        {aboutUsText.map((info, indexAbout) => (
          <NousPageCell key={indexAbout} title={info.title}>
            {info.descriptions.map((description, indexDescription) => (
              <div key={indexDescription}>
                <div className="mb-3">
                  {description.description}
                </div>
              </div>
            ))}
          </NousPageCell>
        ))}
      </section>
    </div>
  );
};

const ourValueInfo = (ourValue) => {
  return (
    <NousPageCell title="L’Église se base sur 4 valeurs clés :">
      {ourValue.map((value, indexValue) => (
        <div key={indexValue}>
          <dl className="mb-4">
            <dt className="font-semibold">· {value.title}</dt>
            <dd>{value.description}</dd>
          </dl>
        </div>
      ))}
    </NousPageCell>
  );
};

const KnowUs = ({ title, aboutUsText, ourValuesText }) => {
  return (
    <Highlight isPrimary={true} title={title}>
      <div class="text-left lg:grid lg:grid-cols-2 lg:gap-x-28">
        {aboutUsText && AboutUsInfo(aboutUsText)}
        {ourValuesText && ourValueInfo(ourValuesText)}
      </div>
    </Highlight>
  );
};

const Beliefs = ({ title, beliefs }) => {
  return (
    <Highlight title={title}>
      <div class="text-left">
        <div className="grid grid-cols-1 gap-16 lg:grid-cols-2 lg:gap-32">
          {beliefs.map((belief, indexBelief) => (
            <NousPageCell key={indexBelief} title={belief.title}>
              {belief.description}
            </NousPageCell>
          ))}
        </div>
      </div>
    </Highlight>
  );
};

const NousPage = ({ data }) => {
  const page = useNousPage(data.allNousPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Nous Connaître"}>
      {page && (
        <KnowUs
          title={page.title}
          aboutUsText={page.aboutUsInfo}
          ourValuesText={page.ourValues}
        />
      )}

      {page && (
        <Beliefs title={page.subtitle} beliefs={page.ourBelief} ></Beliefs>
      )}
    </Layout>
  );
};


export const query = graphql`
  query {
  allNousPages {
    nodes {
      heroBannerType
      buttons {
        text
        url
        subject
        default
      }
      heroBannerImage {
        src
      }
      aboutUsInfo {
        title
        descriptions {
          description
        }
      }
      subtitle
      ourBelief {
        description
        title
      }
      ourValues {
        description
        title
      }
    }
  }
}

`;

export default NousPage;
